


import Component from 'vue-class-component';
import { Vue, Watch } from 'vue-property-decorator';
// import { mapGetters } from 'vuex';
import videojs, { VideoJsPlayer } from 'video.js';
// import { IFixedDraggableChild } from '@/_types/fixed-draggable-child.interface';
// import { Subject } from 'rxjs';

@Component({
  name: 'stream-player',
  props: {
    src: {
      type: String,
      default: '',
    },
    // showFloatButton: {
    //   type: Boolean,
    //   default: false,
    // },
  },
  computed: {
    // ...mapGetters({
    //   floatingStreamSrc: '_eventStore/floatingStreamSrc',
    // }),
  },
})
export default class StreamPlayer extends Vue /* implements IFixedDraggableChild */ {

  public src: string;
  // public showFloatButton: boolean;
  // public floatingStreamSrc: string;
  public player: VideoJsPlayer = null;
  // public dragZoneMouseDown$: Subject<MouseEvent> = new Subject<MouseEvent>();

  public beforeDestroy(): void {
    if (this.player) {
      this.player.dispose();
    }
  }

  // public get isFloating(): boolean {
  //   return this.floatingStreamSrc === this.src;
  // }

  @Watch('src', { immediate: true })
  private onSrcChange(newValue: string, prevValue: string): void {
    if (prevValue || !newValue || this.player) {
      // should be already initialized
      return;
    }

    this.$nextTick(() => {
      if (this.player) {
        return;
      }
      this.player = videojs(this.$refs.video, {}, () => {
        this.player.play();
      });

      // const controlBar = this.player.getChild('controlBar');

      // controlBar.on('mousedown', this.onPlayerControlBarMouseDown);

      // if (this.showFloatButton) {
      //   const Component = videojs.getComponent('Component');
      //   const component = new Component(this.player);
      //   const button = component.addChild('Button', {
      //     clickHandler: (): void => {
      //       if (this.isFloating) {
      //         this.$store.dispatch('_eventStore/setFloatingStreamSrc', null);
      //       } else {
      //         this.$store.dispatch('_eventStore/setFloatingStreamSrc', this.src);
      //       }
      //     },
      //   });
      //   button.addClass('vjs-button-float');
      //   const el = button.el();
      //   // TODO: add title attribute
      //   el.innerHTML = `
      //     <span aria-hidden="true" class="vjs-icon-placeholder" title="${(this.isFloating ? this.$t('streamPlayer.unstick') : this.$t('streamPlayer.stick'))}">
      //       <svg xmlns="http://www.w3.org/2000/svg" style="width: 100%; height: 100%;" viewBox="0 -51 512 512">
      //         <path style="fill: #fff; fill-rule: nonzero;" d="M 0 0 L 0 410 L 512 410 L 512 0 Z M 482 380 L 30 380 L 30 30 L 482 30 Z M 482 380 " />
      //         <path style="fill: #fff; fill-rule: nonzero;" d="M 180.375 310 L 121.214844 310 L 214.105469 217.105469 L 192.894531 195.894531 L 100 288.785156 L 100 229.625 L 70 229.625 L 70 340 L 180.375 340 Z M 180.375 310 " />
      //         <path style="fill: #fff; fill-rule: nonzero;" d="M 319.105469 214.105469 L 412 121.214844 L 412 180.375 L 442 180.375 L 442 70 L 331.625 70 L 331.625 100 L 390.785156 100 L 297.894531 192.894531 Z M 319.105469 214.105469 " />
      //       </svg>
      //     </span>
      //   `;
      //   controlBar.addChild(component, {});
      // }

      this.player.src({
        src: this.src,
        // type: 'application/x-mpegURL',
        // withCredentials: true
      });
    });
  }

  // private onPlayerControlBarMouseDown(event: MouseEvent): void {
  //   this.dragZoneMouseDown$.next(event);
  // }
}

